import React from 'react'
import { Image } from 'components/builder/blocks/shared/Image'
import PropTypes from 'prop-types'
import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material'
import Slider from 'react-slick'
import { createProvenTheme } from '../../../../styles/theme-proven'
import './styles.scss'
import ProvenIconWithText from '../../proven-icon-with-text'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DuoIngredients from '../duo-ingredients'

const themeProven = createProvenTheme()

function ProductDetails({
  title,
  subtitle,
  description,
  size,
  features,
  carouselImagesUrls,
  subscribeAndSaveSelector,
  actionButton,
  tags,
  horizontallyReversed,
  isOneTimeAddOnsProduct,
  ingredients,
  hideTargetingSection,
  hideIngredientsSection
}) {
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))

  const settings = {
    customPaging: function (i) {
      const image = carouselImagesUrls[i]
      return (
        <Box sx={{ position: 'relative' }}>
          <Image
            lazy
            aspectRatio={1}
            image={image}
            backgroundSize="cover"
            backgroundPosition="center"
            key={i}
          />
        </Box>
      )
    },
    dots: true,
    infinite: true,
    dotsClass: 'slick-dots slick-thumb',
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false
  }

  return (
    <Box
      className="congratulation-product"
      sx={{
        pt: 6,
        pb: 12,
        display: 'grid',
        gridTemplateColumns: isLgUp
          ? 'repeat(12, 1fr)'
          : isMdUp
          ? 'repeat(8, 1fr)'
          : 'repeat(4, 1fr)',
        gridTemplateRows: 'auto auto auto',
        gap: {
          md: 4
        },
        gridTemplateAreas: {
          xs: `"image image image image"
          "description description description description"`,
          md: horizontallyReversed
            ? `"description description description description image image image image"`
            : `"image image image image description description description description"`,
          lg: horizontallyReversed
            ? `"description description description description description . image image image image image image"`
            : `"image image image image image image . description description description description description"`
        },
        [isLgUp]: {
          pt: 16,
          pb: 16
        }
      }}
    >
      {/**
       * https://github.com/kenwheeler/slick/issues/982
       * min-width: 0 and min-height: 0
       */}
      <Box gridArea="image" sx={{ marginBottom: { xs: 4, lg: 0 }, minWidth: 0, minHeight: 0 }}>
        <Slider {...settings}>
          {(carouselImagesUrls || []).map((image, i) => (
            <Box sx={{ position: 'relative' }} key={i}>
              <Image aspectRatio={1} image={image} backgroundSize="cover" />
            </Box>
          ))}
        </Slider>
      </Box>
      <Box gridArea="description">
        <Typography variant="title1" color={themeProven.palette.gray.darkGray}>
          {subtitle}
        </Typography>
        <Typography
          variant={isLgUp ? 'h2' : 'h3'}
          sx={{ marginTop: 1, marginBottom: { xs: 3, lg: 2 } }}
          color="gray.elysian"
        >
          {title}
        </Typography>
        <Typography variant="body2" color={themeProven.palette.gray.darkGray}>
          {description}
        </Typography>
        {size && (
          <Typography
            variant="body2"
            color={themeProven.palette.gray.darkGray}
            sx={{ marginTop: 2, mb: { xs: 3, lg: 6 } }}
          >
            Size: {size}
          </Typography>
        )}
        {subscribeAndSaveSelector && (
          <Box
            data-testid="product-detail-subscribe-and-save-selector-container-testId"
            sx={{ mt: 8.5 }}
          >
            {subscribeAndSaveSelector}
          </Box>
        )}
        <Box sx={{ textAlign: { xs: 'center', lg: 'left' }, mt: 6 }}>{actionButton}</Box>
        <Stack
          direction="row"
          gap={3}
          pt={3}
          sx={{ justifyContent: { xs: 'center', lg: 'flex-start' } }}
        >
          <ProvenIconWithText
            name="shipping"
            type="system"
            gap={1}
            color="gray.elysian"
            iconSize="1.125rem"
          >
            <Typography variant="footnote" color="gray.darkGray">
              Free shipping
            </Typography>
          </ProvenIconWithText>
          <ProvenIconWithText
            name="free-reformulations"
            type="system"
            gap={1}
            color="gray.elysian"
            iconSize="1.125rem"
          >
            <Typography variant="footnote" color="gray.darkGray">
              Free reformulation
            </Typography>
          </ProvenIconWithText>
        </Stack>

        <Box>
          {features.map((f, index) => {
            return (
              <Accordion key={`accordion-${index}`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{f.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: f.paragraph
                    }}
                  ></Typography>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Box>
        {!isOneTimeAddOnsProduct && (
          <DuoIngredients
            tags={tags}
            ingredients={ingredients}
            hideTargetingSection={hideTargetingSection}
            hideIngredientsSection={hideIngredientsSection}
          />
        )}
      </Box>
    </Box>
  )
}

ProductDetails.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  size: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paragraph: PropTypes.string
    })
  ).isRequired,
  actionButton: PropTypes.node.isRequired,
  subscribeAndSaveSelector: PropTypes.node,
  renderIngredientsListDrawer: PropTypes.func,
  tags: PropTypes.array,
  horizontallyReversed: PropTypes.bool
}

export default ProductDetails
