import { Stack, Typography } from '@mui/material'
import { capitalize } from 'lodash'
import ProvenIconWithText from '../../proven-icon-with-text'
import React from 'react'
import useCurrency from '../../../../hooks/useCurrency'
import { bfcmCampaign } from '../../../../constants/configs/campaigns'
import useWidth from '../../../../hooks/useWidth'
import { formatPrice } from '../../../../utils/helpers'

function SavingLegend({ savingAmount, isOneTime, frequency, frequencyUnit, sx }) {
  const { currencySymbol } = useCurrency()
  const width = useWidth()
  const now = new Date().getTime()
  const activeCampaign = bfcmCampaign.find(c => {
    return now > Date.parse(c.startDateTime) && now < Date.parse(c.endDateTime)
  })
  const isBFCM = activeCampaign !== undefined
  const isMobile = width === 'xs' || width === 'sm'
  const isCongratsPage = location?.pathname && location?.pathname === '/account/congratulations'
  return (
    <Stack
      gap={2}
      justifyContent="flex-start"
      alignItems={{ xs: 'center', md: 'flex-start' }}
      sx={sx}
    >
      {!isBFCM && (
        <Typography variant="footnote" color="gray.darkGray">
          {`${isOneTime ? '' : 'Subscribe & '}Save. `}
          {isOneTime
            ? 'Ships Once. 2-Month Supply.'
            : `Auto-Refill Every ${frequency} ${capitalize(frequencyUnit)}s.`}
        </Typography>
      )}
      {isBFCM && isCongratsPage && (
        <div
          style={{
            paddingTop: isMobile ? '5px' : '5px',
            paddingLeft: isMobile ? '5px' : '40px',
            textAlign: 'center'
          }}
        >
          <Typography
            color="gray.darkGray"
            sx={{ display: 'block', fontSize: '14px', fontWeight: 600 }}
          >
            $99 with code: DEALOFTHEYEAR
          </Typography>
          <Typography variant="footnote" color="gray.darkGray" sx={{ display: 'block' }}>
            {' '}
            Auto-Refill Price $129.99 Every 2 Months.
          </Typography>
        </div>
      )}

      <Stack direction="row" gap={3} justifyContent={{ xs: 'center', md: 'flex-start' }}>
        {!isOneTime && (
          <ProvenIconWithText
            name="shield"
            type="system"
            gap={1}
            color="gray.elysian"
            iconSize="1.125rem"
          >
            <Typography variant="footnote" color="gray.darkGray">
              Cancel Anytime
            </Typography>
          </ProvenIconWithText>
        )}
        <ProvenIconWithText
          name="shipping"
          type="system"
          gap={1}
          color="gray.elysian"
          iconSize="1.125rem"
        >
          <Typography variant="footnote" color="gray.darkGray">
            Free Shipping
          </Typography>
        </ProvenIconWithText>
        {isOneTime && (
          <ProvenIconWithText
            name="free-reformulations"
            type="system"
            gap={1}
            color="gray.elysian"
            iconSize="1.125rem"
          >
            <Typography variant="footnote" color="gray.darkGray">
              Free Reformulation
            </Typography>
          </ProvenIconWithText>
        )}
      </Stack>
    </Stack>
  )
}

export default SavingLegend
