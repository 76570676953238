import React from 'react'
import { Stack, Typography } from '@mui/material'
import useCurrency from '../../../../hooks/useCurrency'
import { formatPrice } from '../../../../utils/helpers'
import { INSIGNIFICANT_DISCOUNT_CENTS } from '../../../../constants/constants'

function ProductPricingWithProofing({
  productName,
  fullPriceColor,
  priceBeforeDiscount,
  priceAfterDiscount: currentPrice
}) {
  const crossedOutPrice =
    currentPrice &&
    priceBeforeDiscount &&
    Math.abs(currentPrice - priceBeforeDiscount) > INSIGNIFICANT_DISCOUNT_CENTS
      ? priceBeforeDiscount
      : null

  const { currencySymbol, currencyToDisplayOrEmptyIfUS } = useCurrency()

  return (
    <Stack>
      <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
        {productName && (
          <Typography variant="title1" color="primary.main">
            {productName}
          </Typography>
        )}
        <Typography variant="title1" color={fullPriceColor || 'primary.main'}>
          {currencySymbol}
          {formatPrice(currentPrice)}
          {currencyToDisplayOrEmptyIfUS}
        </Typography>
        {crossedOutPrice && (
          <Typography
            variant="priceSlashOut"
            color="gray.darkGray"
            sx={{ fontFeatureSettings: `'liga' off` }}
          >
            {currencySymbol}
            {formatPrice(crossedOutPrice)}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
export default ProductPricingWithProofing
