import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './styles.scss'
import ProductPricingWithProofing from '../product-pricing-with-proofing'
import useActiveCampaign from 'hooks/use-active-campaign'
import useCurrency from '../../../../hooks/useCurrency'
import { countryCodeSelect } from '../../../../utils/selectors'
import { Typography } from '@mui/material'
import usePriceWithDiscounts from '../../../../hooks/usePriceWithDiscounts'

//TODO on ENG-513 try to unify ShopPricingWithProofing/PricingWithProofing/ProductPricingWithProofing if it's possible and clean up not needed props
// PricingWithProofing for only system product
const PricingWithProofing = ({
  productName = '3-Product System',
  centered = false,
  showOneTimePrice = false,
  showDiscountedCampaignPrice = false,
  hasNoSlashOut = false,
  hideFreeShipping = false,
  byPassCouponAppliedCheck = false,
  textColor,
  countryInfo,
  variant,
  fullPriceColor
}) => {
  const activeCampaign = useActiveCampaign()
  const { getSystemProductSavePrice } = usePriceWithDiscounts()
  const countryCode = useSelector(countryCodeSelect)
  const currencyInfoFromAccount = useCurrency()
  const { currencySymbol, currencyToDisplayOrEmptyIfUS, currency } =
    countryInfo || currencyInfoFromAccount
  const { fullPriceFormatted, displayPrice, oldPriceFormatted, showPriceWithCouponDiscount } =
    getSystemProductSavePrice({
      showOneTimePrice,
      byPassCouponAppliedCheck,
      showDiscountedCampaignPrice
    })

  const headClasses = classnames('alignCenter', { textCenter: centered })

  const saveAndFreeShippingMessage = `SAVE ${currencySymbol}${Math.round(
    fullPriceFormatted - displayPrice
  )} + FREE SHIPPING`

  if (variant === 'cart')
    return (
      <ProductPricingWithProofing
        productName={productName}
        currencySymbol={currencySymbol}
        currentPrice={displayPrice}
        countryCode={countryCode}
        currency={currency}
        showOldPrice={true}
        fullPriceFormatted={oldPriceFormatted}
        showCurrencyInOldPrice={true}
        showProductName={hasNoSlashOut}
        fullPriceColor={fullPriceColor}
      />
    )

  if (variant === 'button')
    return (
      <React.Fragment>
        <Typography variant="button" mr={0.5}>
          {currencySymbol}
          {displayPrice} {currencyToDisplayOrEmptyIfUS}
        </Typography>
        <Typography
          variant="priceSlashOut"
          color="gray.darkGray"
          sx={{ fontSize: { xs: '13px !important', md: '14px !important' } }}
        >
          {currencySymbol}
          {oldPriceFormatted} {currencyToDisplayOrEmptyIfUS}
        </Typography>
      </React.Fragment>
    )

  return (
    <div styleName="pricing">
      <div>
        <div styleName={headClasses}>
          {hasNoSlashOut && (
            <>
              <span styleName="no-slash-out">{productName}</span>
            </>
          )}
          <span styleName="new" style={{ color: textColor || '#7BC4AB' }}>
            {currencySymbol}
            {displayPrice} {currencyToDisplayOrEmptyIfUS}
          </span>
          <span styleName="old">
            {currencySymbol}
            {oldPriceFormatted} {currencyToDisplayOrEmptyIfUS}
          </span>
        </div>
      </div>
      {!hideFreeShipping && (
        <p styleName="freeShipping">
          {showPriceWithCouponDiscount && activeCampaign.freeShippingText
            ? activeCampaign.freeShippingText
            : saveAndFreeShippingMessage}
        </p>
      )}
    </div>
  )
}

PricingWithProofing.defaultProps = {
  variant: 'common'
}

PricingWithProofing.propTypes = {
  variant: PropTypes.oneOf(['common', 'cart', 'button']),
  centered: PropTypes.bool,
  price: PropTypes.number,
  productName: PropTypes.string,
  hasSlashOut: PropTypes.bool,
  showOneTimePrice: PropTypes.bool,
  showDiscountedCampaignPrice: PropTypes.bool,
  hideFreeShipping: PropTypes.bool,
  textColor: PropTypes.string
}

export default PricingWithProofing
