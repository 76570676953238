import React from 'react'
import propTypes from 'prop-types'
import { Box } from '@mui/material'

const ProvenBottomDrawerStatic = ({ children, height, customBackground }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 5,
        bottom: 0,
        left: 0,
        width: 1,
        background: customBackground ? customBackground : theme => theme.palette.background.default,
        px: 2,
        pt: 2,
        pb: 2,
        borderTopLeftRadius: theme => theme.shape.borderRadius * 2,
        borderTopRightRadius: theme => theme.shape.borderRadius * 2,
        height: height,
        boxShadow:
          '0px -64.81px 46.8519px rgb(32 68 86 / 3%), 0px -38.52px 25.4815px rgb(32 68 86 / 1%), 0px -20px 13px rgb(32 68 86 / 2%), 0px -8.15px 6.51852px rgb(32 68 86 / 1%), 0px -1.85px 3.14815px rgb(32 68 86 / 0%)'
      }}
    >
      {children}
    </Box>
  )
}

ProvenBottomDrawerStatic.propTypes = {
  children: propTypes.node.isRequired,
  height: propTypes.oneOfType([propTypes.string, propTypes.number])
}

ProvenBottomDrawerStatic.defaultProps = {
  height: 'unset'
}

export default ProvenBottomDrawerStatic
